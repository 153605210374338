$(document).ready(function () {

  $("#contact-form").submit(function (e) {
    e.preventDefault();
    var _form = $(this);
    var serialize = _form.serializeArray();
    var data = {};
    var btn = _form.find("button[type=submit]");
    var defaultError = "Sorry, that didn't work, please try again!";
    var defaultSuccess = "Thank you, let the learning begin!";

    serialize.forEach(function (el) {
        data[el.name] = el.value
    });

    btn.text("Submitting...");
    btn.attr('disabled', 'disabled');

    grecaptcha.ready(function() {
      grecaptcha.execute('reCAPTCHA_site_key', {action: 'submit'}).then(function(token) {
      
    $.ajax({
        url: "https://getform.io/f/db7f1f0b-b28f-4651-bf4b-fdb1fed6c1bf",
        type: "POST",
        contentType: 'application/json',
        dataType: "json",
        data: JSON.stringify(data),
        success: function (res) {
            btn.text("Thanks!");
            _form.trigger("reset");
           
            if (_form.find(".message").hasClass("error")) 
                _form
                    .find(".message")
                    .removeClass("error");
        },

        error: function (err) {
            btn.text("Subscribe");
            btn.removeAttr('disabled');
            
            if (_form.find(".message").hasClass("success")) 
                _form
                    .find(".message")
                    .removeClass("success");
        }
    });

    _form
      .find(".message")
      .show()
      .addClass('success')
      .text(defaultSuccess);

    });
  });

    return false;
  });

  // Smooth scrolling
  $('a[href*="#"]').on('click', function (e) {
    $('html,body').animate({
                             scrollTop: $($(this).attr('href')).offset().top
                           }, 500);
    e.preventDefault();
  });

  // Footer date
  document.getElementById("year").innerHTML = new Date().getFullYear();

});