const getLoginUrl = async () => {
    const result = await axios.get(
        fnBaseUrl + '/auth/loginWithGithub', {});

    return result?.data;
};

const getGitHubProfile = async (access_token) => {
    const result = await axios.get(
        fnBaseUrl + '/auth/getUserGithub',
        {
            params: {
                access_token
            }
        });

    return result?.data;
};

const saveProfile = async (data) => {
    const result = await axios.post(
        fnBaseUrl + '/auth/saveProfile',
        data);

    return result?.data;
};

window.UsernameService = {
    getLoginUrl,
    getGitHubProfile,
    saveProfile
};