window.addEventListener("load", async () => {
    if (!!document.getElementById('account-form-component')) {
        // Loader
        const loaderEl = document.getElementById('setup-account-loader');
        const formEl = document.getElementById('setup-account-form');
        const formMessageEl = document.getElementById('setup-account-form-message');
        let installId = new URLSearchParams(window.location.search).get('installation_id');

        // Start loader
        const toggleLoader = (show = false) => {
            if (!show) {
                loaderEl.classList.add('hidden');
                formEl.classList.remove('hidden');
            } else {
                loaderEl.classList.remove('hidden');
                formEl.classList.add('hidden');
                formMessageEl.classList.add('hidden');
            }
        };

        // Form submit
        formEl.addEventListener('submit', async (e) => {
            e.preventDefault();
            let data = {};

            // Show loader
            toggleLoader(true);

            // Get form data
            let formData = new FormData(e.target);
            for (let [key, value] of formData.entries()) {
                data[key] = value;
            }

            // Get auth token
            if (installId) data.extGitHubInstallationId = parseInt(installId);

            // Submit form
            try {
                const result = await OrganizationService.saveContact(data);
                window.location.href = '/getting-started/';
            } catch (e) {
                toggleLoader();
                const mes = e.response?.data?.message || null;
                console.log(mes);
                formMessageEl.classList.remove('text-main-100');
                formMessageEl.classList.add('text-red-500');
                formMessageEl.innerHTML = mes;
            } finally {
                formMessageEl.classList.remove('hidden');
            }
        });
    }
}, false);