const saveContact = async (data) => {
    const result = await axios.post(
        fnBaseUrl + '/org/saveContact',
        {data: data});

    return result?.data;
};

window.OrganizationService = {
    saveContact
};