window.addEventListener("load", async () => {
    if (!!document.getElementById('login-github-component')) {
        // Loader
        const loaderEl = document.getElementById('login-github-loader');
        const buttonEl = document.getElementById('login-github-button');

        // Start loader
        const toggleLoader = (show = false) => {
            if (!show) {
                loaderEl.classList.add('hidden');
                buttonEl.classList.remove('hidden');
            } else {
                loaderEl.classList.remove('hidden');
                buttonEl.classList.add('hidden');
            }
        };

        // Listen for click on reserve button
        buttonEl.addEventListener('click', async (e) => {
            e.preventDefault();
            toggleLoader(true);

            try {
                const {data} = await UsernameService.getLoginUrl();
                if (!data?.url) throw "No URL found";
                location.assign(data.url);
            } catch (e) {
                console.log(e);
                toggleLoader();
            }
        });
    }
}, false);