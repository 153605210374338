window.addEventListener("load", async () => {
    if (!!document.getElementById('reserve-component')) {
        // Loader
        const loaderEl = document.getElementById('reserve-loader');
        const formEl = document.getElementById('reserve-form');
        const formMessageEl = document.getElementById('reserve-form-message');
        let providerToken = new URLSearchParams(window.location.hash.split('#')[1]).get('provider_token');
        let accessToken = new URLSearchParams(window.location.hash.split('#')[1]).get('access_token');
        let githubProfile = null;

        // Start loader
        const toggleLoader = (show = false) => {
            if (!show) {
                loaderEl.classList.add('hidden');
                formEl.classList.remove('hidden');
            } else {
                loaderEl.classList.remove('hidden');
                formEl.classList.add('hidden');
                formMessageEl.classList.add('hidden');
            }
        };

        try {
            // Get username
            if (providerToken) {
                // Get GitHub
                githubProfile = await UsernameService.getGitHubProfile(providerToken);

                // Remove loader
                toggleLoader();

                // Set username
                let usernameEl = document.getElementById('username');
                if (usernameEl && githubProfile?.login) usernameEl.value = githubProfile.login;
            }
        } catch (e) {
            console.log(e);
        }

        // Form submit
        formEl.addEventListener('submit', async (e) => {
            e.preventDefault();
            let data = {};

            // Show loader
            toggleLoader(true);

            // Get form data
            let formData = new FormData(e.target);
            for (let [key, value] of formData.entries()) {
                data[key] = value;
            }

            // Get GitHub profile
            if (githubProfile) data.githubProfile = githubProfile;

            // Get provider token
            if (providerToken) data.githubToken = providerToken;

            // Get auth token
            if (accessToken) data.authToken = accessToken;

            // Submit form
            try {
                const result = await UsernameService.saveProfile(data);

                // Show message
                toggleLoader();
                formEl.classList.add('hidden');
                formMessageEl.classList.remove('text-red-500');
                formMessageEl.classList.add('text-main-100');
                formMessageEl.innerHTML = result?.message || null;
            } catch (e) {
                toggleLoader();
                const mes = e.response?.data?.message || null;
                console.log(mes);
                formMessageEl.classList.remove('text-main-100');
                formMessageEl.classList.add('text-red-500');
                formMessageEl.innerHTML = mes;
            } finally {
                formMessageEl.classList.remove('hidden');
            }
        });
    }
}, false);